// 邮箱格式验证函数
export function isValidateEmail (email) {
    const mailReg = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/;
    // 检查邮箱是否为空
       if (!email.trim()) {
           return {
               showClose: true,
               duration: 1500,
               message: "邮箱不能为空！"
           };
       }
   
       // 检查邮箱格式是否正确
       if (!mailReg.test(email)) {
           return {
               showClose: true,
               duration: 1500,
               message: "邮箱格式不正确！"
           };
       }
       
       // 返回 null 表示验证通过
       return null;
};


export function isValidatePhoneNumber(phoneNumber) {
    const phoneReg = /^1[3-9]\d{9}$/;

    // 检查手机号是否为空
    if (!phoneNumber.trim()) {
        return {
            showClose: true,
            duration: 1500,
            message: "手机号不能为空！"
        };
    }

    // 检查手机号格式是否正确
    if (!phoneReg.test(phoneNumber)) {
        return {
            showClose: true,
            duration: 1500,
            message: "手机号格式不正确！"
        };
    }

    // 返回 null 表示验证通过
    return null;
}