<template>
	<div class="">
		<iframe width=1 height=1 name=xtsub src=''></iframe>
		<div id="">
			<el-image style="height: 400px; width: 100%" :src="require('@/assets/images/tg.jpg')"></el-image>
		</div>
		<div class="page-content" style="margin-top: 40px;">
			<div>
				<el-breadcrumb separator="/">
					<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
					<el-breadcrumb-item>在线投稿</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
			<div style="border: solid 1px #767288;margin: 40px auto;width: 80%;padding-top: 30px;">
				<div style="width: 80%;margin: 40px auto;">
					<div>
						<p style="color: #333333;font-size: 24px;text-align: center;">在线投稿</p>
						<el-divider></el-divider>
					</div>
					<div class="loading">

						<el-form :model="releaseForm" :inline="true" :rules="rules" ref="releaseForm"
							class='form-horizontal' id='frm_1' method=POST action='https://crm.xtcrm.com/uin/submit.xt' target=xtsub>
							<input type=hidden name=cu value='PTzRT1FjSlRramJO'><input type=hidden name=md
								value='e828375de7fa54eb0c86346f12d22250'><input type=hidden name=wid value='10'><input
								type=hidden name='charset_test' value='中国'>

							<el-form-item label="作者姓名" label-width="100px" prop="authorName">
								<el-input v-model="releaseForm.authorName" autocomplete="off" type="edit"  class="form-box-input"
									name="L1"></el-input>
							</el-form-item>
							<el-form-item label="投稿邮箱" label-width="100px" prop="eMail">
								<el-input v-model="releaseForm.eMail" autocomplete="off" type="edit"  class="form-box-input"
									name="L4"></el-input>
							</el-form-item>
							<el-form-item label="联系电话" label-width="100px" prop="phone">
								<el-input v-model="releaseForm.phone" autocomplete="off" type="text"  class="form-box-input"
									name="L2"></el-input>
							</el-form-item>
							<el-form-item label="单位名称" label-width="100px" prop="unitName">
								<el-input v-model="releaseForm.unitName" autocomplete="off" type="edit"  class="form-box-input"
									name="L6"></el-input>
							</el-form-item>
							<el-form-item label="投稿期刊" style="width: 98.5%" label-width="100px" prop="periodical">
								<el-input v-model="releaseForm.periodical" autocomplete="off" class="form-box-input"
									type="edit" name="L3"></el-input>
							</el-form-item>
							<el-form-item label="研究领域" label-width="100px" prop="research">
								<el-select v-model="releaseForm.research" placeholder="研究领域"  name='L8' class='select-box'>
									<el-option label="环境" value="环境"></el-option>
									<el-option label="工业工程" value="工业工程"></el-option>
									<el-option label="机械" value="机械"></el-option>
									<el-option label="心理学" value="心理学"></el-option>
									<el-option label="海洋工程" value="海洋工程"></el-option>
									<el-option label="生物医学" value="生物医学"></el-option>
									<el-option label="高被引" value="高被引"></el-option>
									<el-option label="管理科学" value="管理科学"></el-option>
									<el-option label="数学" value="数学"></el-option>
									<el-option label="其他" value="其他"></el-option>
								</el-select>
							</el-form-item>
							<br />
							<el-form-item label="论文标题" style="width: 98.5%" label-width="120px" prop="title">
								<el-input v-model="releaseForm.title" autocomplete="off" class="form-box-input"
									type="edit" name="L5"></el-input>
							</el-form-item>
							<el-form-item label="所有作者姓名" style="width: 98.5%" label-width="120px" prop="allName">
								<el-input v-model="releaseForm.allName" autocomplete="off" class="form-box-input"
									type="edit" name="L7"></el-input>
							</el-form-item>
							<el-form-item label="所有作者邮箱" style="width: 98.5%" label-width="120px" prop="allEmail">
								<el-input v-model="releaseForm.allEmail" autocomplete="off" class="form-box-input"
									type="edit"></el-input>
							</el-form-item>
							<el-form-item style="width: 98%;margin-left: 5%;">
								<el-upload class="upload-demo" ref="upload"
									action="http://103.120.82.111:8066/upload/file" :on-remove="handleRemove"
									:on-success="onSuccess" multiple :limit="1" :on-exceed="handleExceed"
									:file-list="fileList">
									<el-button size="medium" type="info">上传论文</el-button>
									<template #tip>
										<div class="el-upload__tip" style="color: red">
											注：文件格式仅限于.doc, .docx
										</div>
									</template>
								</el-upload>
							</el-form-item>
							<p class="text-center">
								<input class="form-box-btn" style="display: none;" ref="submitButtonInput" type="submit"
									value="  提交  " name="submit" />
							</p>
							<el-form-item style="text-align: center; width: 98.5%;">
								<el-button type="primary" @click="onSubmit('releaseForm','open')"
									style="background-color: #18305b; width: 282px">提交</el-button>
							</el-form-item>
						</el-form>
					</div>
					<div>
						<p style="color: red;">注意事项：</p>
						<div style="line-height: 8px;color: #333333;font-size: 14px;">
							<p>1. 提交成功后如需更改信息，请联系service@iamset.cn。 </p>
							<p>2. 为便于享受优惠折扣，IAMSET会员请提供已发表论文编号；被推荐作者请提供推荐人姓名及论文编号</p>
						</div>
					</div>
				</div>
			</div>
		</div>

	</div>
</template>
<script>
	import {
		isValidateEmail,isValidatePhoneNumber
	} from "@/utils/validate"
	import $http from "@/request/http";
	export default {
		data() {
			
			//校验邮箱
			const validateEmaill = (rule, value, callback) => {
				const validationResult = isValidateEmail(value); // 使用 validateContactEmail 函数验证邮箱
				if (validationResult) {
					callback(new Error(validationResult.message)); // 如果验证结果不为空，说明验证失败，返回错误信息
				} else {
					callback(); // 验证通过，不返回错误信息
				}
			};
			//校验手机号
			const validatePhone = (rule, value, callback) => {
				const validationResult = isValidatePhoneNumber(value); // 使用 validateContactEmail 函数验证邮箱
				if (validationResult) {
					callback(new Error(validationResult.message)); // 如果验证结果不为空，说明验证失败，返回错误信息
				} else {
					callback(); // 验证通过，不返回错误信息
				}
			};
			return {
				activeName: "first",
				fileList: [],
				releaseForm: {
					authorName: "",
					unitName: "",
					eMail: "",
					phone: "",
					periodical: "",
					research: "",
					title: "",
					allName: "",
					allEmail: "",
					spareFieldO: "",
					spareFieldT: "tg",
					spareFieldS: "http://www.iamset.cn/"
				},
				rules: {
					authorName: [{
						required: true,
						trigger: "change",
						message: "请输入作者姓名"
					}, ],
					unitName: [{
						required: true,
						trigger: "blur",
						message: "请输入单位名称"
					}, ],
					eMail: [{
						required: true,
						trigger: "blur",
						validator: validateEmaill
					}, ],
					phone: [{
						required: true,
						trigger: "blur",
						validator:validatePhone
					}, ],

					research: [{
						required: true,
						trigger: "blur",
						message: "请输入研究领域"
					}, ],
					title: [{
						required: true,
						trigger: "blur",
						message: "请输入论文标题"
					}, ],
					allName: [{
						required: true,
						trigger: "blur",
						message: "请输入所有作者姓名"
					}],
					allEmail: [{
						required: true,
						trigger: "blur",
						validator: validateEmaill
					}],


				},

			};
		},

		methods: {
			handleExceed(files, fileList) {
				this.$message.warning(
					`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
					 files.length + fileList.length
					} 个文件`
				);
			},
			onSuccess(esponse, file, fileList) {
				this.fileList = fileList;
				this.releaseForm.spareFieldO = this.fileList[0].response.data
			},
			handleRemove(file, fileList) {
				this.fileList = fileList;
				this.releaseForm.spareFieldO = null
			},
			onSubmit(releaseForm) {
				this.$refs.submitButtonInput.click();

				this.$refs[releaseForm].validate((valid) => {
					if (valid) {
						let _this = this;
						$http
							.post("addOnlineSubmission", this.releaseForm, "loading")
							.then(function(res) {
								console.log(res)
								if (res.code == 200) {
									_this.$message.success({
										showClose: true,
										duration: 1500,
										message: res.msg,
										type: "success",
										onClose: function() {
											_this.$refs[releaseForm].resetFields();
											_this.$refs.['upload'].clearFiles();
										},
									});
								}

							})
							.catch(function() {});
					} else {
						//   this.$message.error("请检查必填项！");
						this.$notify.error({
							title: "错误",
							message: "请检查必填项！",
						});
						return false;
					}
				});
			},
			resetForm(releaseForm) {
				this.$refs[releaseForm].resetFields();
			},
			getCli() {

				this.releaseForm.periodical = this.$route.query.journalTitle
			}
		},
		created() {
			this.getCli();
		}
	};
</script>

<style lang="scss" scoped>
	.num {
		text-align: center;
		margin-right: 15px;
		color: white;
		width: 22px;
		height: 22px;
		background-image: linear-gradient(90deg, #1b5faa 0%, #00b4e4 100%),
			linear-gradient(#d40016, #d40016);
		background-blend-mode: normal, normal;
	}

	.el-form-item {
		width: 48.5%;
	}
</style>